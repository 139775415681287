<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CCard>
          <CCardHeader>
            Denuncias
          </CCardHeader>
          <CCardBody>
            <CDataTable
              v-show="!loading"
              hover
              striped
              :items="items"
              :fields="fields"
              :items-per-page="pagination.perPage"
              @row-clicked="checkComplaint"
              index-column
              clickable-rows
            >
              <template #type="data">
                <td class="d-flex align-items-center">
									<img
										:src="data.item.type.img_icon"
										class="mr-1"
										style="max-width: 25px;max-height: 25px;">
									{{data.item.type.title}}
								</td>
              </template>
              <template #user="data">
                <td v-if="data.item.user">
									{{data.item.user.name}} {{data.item.user.lastname}}
								</td>
                <td v-else>
									Usuario no Registrado
								</td>
              </template>
              <template #status="data">
								<td>
									<CBadge :color="getBadge(data.item.status[0].status_id)">
										{{data.item.status[0].status.name}}
									</CBadge>
									<p class="mb-0" style="font-size: 10px">{{data.item.status[0].created_at | filterDate}}</p>
								</td>
              </template>
              <template #created="data">
                <td>
									{{data.item.created_at | filterDate}}
								</td>
              </template>
<!--               <template #actions="data">
                <CButton
                  color="warning"
                  size="sm"
                  class="m-1"
                  @click="updateUser(data.item, $event)"
                >
                  <font-awesome-icon icon="user-edit" />
                </CButton>
                <CButton
                  color="danger"
                  size="sm"
                  class="m-1"
                  @click="deleteUser(data.item.id, $event)"
                >
                  <font-awesome-icon icon="trash-alt" />
                </CButton>
              </template> -->
            </CDataTable>
            <div 
              v-if="loading"
              class="d-flex flex-column justify-content-center align-items-center" style="min-height: 300px;">
              <CSpinner
                style="width:4rem;height:4rem;"
                color="danger"
              />
              <p class="text-secondary my-2">Cargando datos ...</p>
            </div>
            <Pagination :data="pagination" :callback="paginate"/>
          </CCardBody>
        </CCard>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import Pagination from '../../helpers/Pagination'
import { getComplaintsByCompany } from '@/api/complaints'
import moment from 'moment'

export default {
  name: 'Complaints',
  components: {Pagination},
  filters: {
    filterDate: function(date){
      return moment(date).format("DD-MM-YYYY HH:mm")
    }
  },
  data: () => {
    return {
      items: [],
      dataLoading: true,
      fields: [
        { key: 'unique_id', label: 'Código' },
        { key: 'type', label: 'Tipo' },
        { key: 'user', label: 'Usuario' },
        { key: 'status', label: 'Estado Actual' },
        { key: 'created', label: 'Fecha creación' }
      ],
      pagination: {
        page: 1,
        perPage: 10,
        total: null
      },
      updatingUser: null,
      showModal: false,
      loading: true
    }
  },
	beforeDestroy(){
		this.$events.remove('reloadComplaints')
	},
  mounted(){
    this.getComplaints()
    this.$events.listen('reloadComplaints', () => {
      this.getComplaints()
    })
  },
  methods: {
    paginate(page, perPage){
      this.pagination = {
        page: page,
        perPage: perPage,
        total: this.pagination.total
      }
			console.log('pagination: ', this.pagination)
      this.getComplaints()
    },
    checkComplaint(complaint){
      this.$store.commit('setShowingComplaint', complaint)
    },
    getComplaints(){
      const data = {
        limit: this.pagination.perPage,
        page: this.pagination.page
      }
      const self = this
      this.loading = true
      getComplaintsByCompany(data)
        .then(function(resp){
					console.log('resp: ', resp)
          self.items = resp.complaints
          self.pagination = {
            page: self.pagination.page,
            perPage: self.pagination.perPage,
            total: resp.total
          }
          self.loading = false
        })
        .catch(function(error){
          console.log(error)
        })
    },
    getBadge (status) {
			const statusMap = [
				'primary','warning','success','danger','primary','info'
			]
      return statusMap[status-1]
    }
  }
}
</script>